// import direction1 from "../../../image/direction1.jpg";
// import direction2 from "../../../image/direction2.jpg";
// import direction3 from "../../../image/direction3.jpg";
// import direction4 from "../../../image/direction4.jpg";
// import direction5 from "../../../image/direction5.jpg";
// import direction5 from "../../../image/direction5.jpg";
import dir1 from "../../../image/dir1.jpg";
import noImage from "../../../image/noImage.jpg";

export const directions = [
  {
    name: "КОЩАВЕЦЬ Ірина Миколаївна",
    text: [
      "Український хореограф",
      "Керівник зразкового ансамблю танцю «Джерельце»",
      "Pаслужений діяч мистецтв України",
      "Лауреат міжнародних і всеукраїнських фестивалів та конкурсів хореографічного мистецтва.",
      "Директор творчої спілки дитячої народної хореографії м.Харкова",
    ],
    image: dir1,
  },
  {
    name: "КОЩАВЕЦЬ Максим Петрович",
    text: ["український хореограф, педагог", "Керівник зразкового ансамблю танцю «Джерельце»", "Лауреат міжнародних і всеукраїнських фестивалів та конкурсів хореографічного мистецтва."],
    image: noImage,
  } /*
  {
    name: "ВАНТУХ Мирослав Михайлович",
    text: [
      "Генеральний директор – художній керівник",
      "Герой України",
      "Народний артист України",
      "Лауреат Національної премії України імені Тараса Шевченка",
      "Голова Національної хореографічної спілки України",
      "Професор",
      "Академік",
    ],
    image: direction1,
  },
  {
    name: "ЗАВАДСЬКИЙ Віктор Станіславович",
    text: ["Директор – розпорядник", "Заслужений працівник культури України"],
    image: direction2,
  },
  {
    name: "СЕДУН Микола Васильович",
    text: ["Заступник генерального директора", "Заслужений працівник культури України"],
    image: direction3,
  },
  {
    name: "ЧЕБЕРКО Олександр Дмитрович",
    text: ["Головний диригент", "Народний артист України"],
    image: direction4,
  },
  {
    name: "ВАНТУХ Валентина Володимирівна",
    text: ["Директор – художній керівник Дитячої хореографічної школи при ансамблі", "Народна артистка України", "Повний кавалер ордену Княгині Ольги"],
    image: direction5,
  },*/,
];
