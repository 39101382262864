import { useState, useEffect } from "react";
import { Button, Typography, useTheme, IconButton, Select, MenuItem, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CloseIcon from "@mui/icons-material/Close";
import { customAlert } from "../../../components/Alert/AlertFunction";

function CreateShedule({ setIsOpen, createShedule, allGroups, loadindCreate }) {
  const theme = useTheme();
  const [shedule, setShedule] = useState({ age_group_id: 0, date: null, time: "", comment: "" });

  return (
    <div style={{ background: "rgba(17, 17, 18, 0.95)" }} className="modalStyles">
      <div
        onClick={(e) => e.stopPropagation()}
        className="modalContentStyles scroll"
        style={{
          background: theme.palette.mode === "light" ? "#F3F1F2" : "#1b1b1d",
          color: theme.palette.text.primary,
          alignItems: "baseline",
          position: "relative",
          maxHeight: "80vh",
          //width: "80vh",
          overflow: "auto",
        }}
      >
        <div style={{ position: "absolute", top: "1rem", right: "1rem", zIndex: "inherit" }}>
          <IconButton aria-label="delete" onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <h3 style={{ width: "100%", textAlign: "center" }}>Створити тренування</h3>
        <div className="createContainer">
          <div className="createBlock">
            <Typography className="createTitle" component="h2">
              Група:
            </Typography>
            <Select className="createSelect" value={shedule?.age_group_id} onChange={(e) => setShedule((prev) => ({ ...prev, age_group_id: e?.target?.value }))} size="small">
              <MenuItem value={0}>Не вказано</MenuItem>
              {allGroups?.map((el) => (
                <MenuItem key={el?.id} value={el?.id}>
                  {el?.name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="createBlock">
            <Typography className="createTitle" component="h2">
              Дата
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker className="createSelect" value={shedule?.date} onChange={(e) => setShedule((prev) => ({ ...prev, date: new Date(e) }))} />
            </LocalizationProvider>
          </div>
          <div className="createBlock">
            <Typography className="createTitle" component="h2">
              Час початку
            </Typography>
            <TextField className="createSelect" value={shedule?.time} onChange={(e) => setShedule((prev) => ({ ...prev, time: e?.target?.value }))} size="small" variant="outlined" type="text" />
          </div>
          <div className="createBlock">
            <Typography className="createTitle" component="h2">
              Коментар
            </Typography>
            <TextField className="createSelect" value={shedule?.comment} onChange={(e) => setShedule((prev) => ({ ...prev, comment: e?.target?.value }))} size="small" variant="outlined" type="text" />
          </div>
        </div>
        <Button disabled={loadindCreate} onClick={() => createShedule({ shedule })} variant="outlined">
          Створити
        </Button>
      </div>
    </div>
  );
}

export default CreateShedule;
