import { useState, useEffect } from "react";
import { Button, Typography, useTheme, IconButton, Select, MenuItem, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { customAlert } from "../../../components/Alert/AlertFunction";
import NewsApi from "../../../api/news";

function NewsCreate({ setIsOpen, createNews, user }) {
  const theme = useTheme();
  const [news, setNews] = useState({});
  const [allHeading, setAllHeading] = useState([]);

  async function getAllHeading() {
    const data = await NewsApi.getAllHeading();
    if (data) {
      setAllHeading(data);
    } else {
      customAlert({ message: "Помилка отримання заголовків" });
    }
  }

  useEffect(() => {
    getAllHeading();
  }, []);

  return (
    <div style={{ background: "rgba(17, 17, 18, 0.95)" }} className="modalStyles">
      <div
        onClick={(e) => e.stopPropagation()}
        className="modalContentStyles scroll"
        style={{
          background: theme.palette.mode === "light" ? "#F3F1F2" : "#1b1b1d",
          color: theme.palette.text.primary,
          alignItems: "baseline",
          position: "relative",
          maxHeight: "80vh",
          //width: "80vh",
          overflow: "auto",
        }}
      >
        <div style={{ position: "absolute", top: "1rem", right: "1rem", zIndex: "inherit" }}>
          <IconButton aria-label="delete" onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <h3 style={{ width: "100%", textAlign: "center" }}>Створити новину</h3>
        <hr className="hr-viss" />
        <div className="createContainer">
          <div className="createBlock">
            <Typography className="createTitle" component="h2">
              Рубрика:
            </Typography>
            <Select className="createSelect" value={news?.heading_id} onChange={(e) => setNews((prev) => ({ ...prev, heading_id: e?.target?.value }))} size="small">
              <MenuItem value={0}>Не вказано</MenuItem>
              {allHeading?.map((el) => (
                <MenuItem key={el?.id} value={el?.id}>
                  {el?.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="createBlock">
            <Typography className="createTitle" component="h2">
              Назва
            </Typography>
            <TextField className="createSelect" value={news?.name} onChange={(e) => setNews((prev) => ({ ...prev, name: e?.target?.value }))} size="small" variant="outlined" type="text" />
          </div>
          <div className="createBlock">
            <Typography className="createTitle" component="h2">
              Заголовок
            </Typography>
            <TextField className="createSelect" value={news?.title} onChange={(e) => setNews((prev) => ({ ...prev, title: e?.target?.value }))} size="small" variant="outlined" type="text" />
          </div>
          <div className="createBlock">
            <div>
              <Typography className="createTitle" component="h2">
                Текст:
              </Typography>
              <Typography className="createTitle" component="h2" style={{ color: "red" }}>
                вказуйте через "/n/"
              </Typography>
            </div>
            <TextField className="createSelect" value={news?.text} onChange={(e) => setNews((prev) => ({ ...prev, text: e?.target?.value }))} size="small" variant="outlined" type="text" />
          </div>
          <div className="createBlock">
            <div>
              <Typography className="createTitle" component="h2">
                Посилання на зображення:
              </Typography>
              <Typography className="createTitle" component="h2" style={{ color: "red" }}>
                вказуйте через "/n/"
              </Typography>
            </div>
            <TextField className="createSelect" value={news?.images} onChange={(e) => setNews((prev) => ({ ...prev, images: e?.target?.value }))} size="small" variant="outlined" type="text" />
          </div>
        </div>
        <Button onClick={() => createNews({ news: { ...news, creator_id: user?.id } })} variant="outlined">
          Створити
        </Button>
      </div>
    </div>
  );
}

export default NewsCreate;
