import { Typography } from "@mui/material";
import { PageContainer } from "../../components/Page.styled";
import { directions } from "./components/mock";
import PhotoCard from "./components/PhotoCard";

function AboutUs() {
  return (
    <PageContainer>
      <div className="aboutUs">
        <Typography variant="h2" style={{ textAlign: "center", marginBottom: "3rem" }}>
          ДЖЕРЕЛЬЦЕ СЬОГОДНІ
        </Typography>
        <div style={{ padding: "0 1rem" }}>
          <Typography variant="h5">
            Зразковий художній колектив України ансамбль танцю "Джерельце" – український колектив народного танцю, 
            що неодноразово брав участь у всеукраїнських змаганнях та отримував призові місця та навіть зараз, 
            під час війни продовжує свою діяльність та виступає на міських заходах.
          </Typography>
        </div>

        <div className="direction">
          <Typography variant="h4" style={{ textAlign: "center", color: "black", margin: "0 0 2rem" }}>
            Дирекція
          </Typography>
          <div className="cards">
            {directions?.map((el, index) => (
              <PhotoCard key={index} direction={el} />
            ))}
          </div>
        </div>
      </div>
    </PageContainer>
  );
}

export default AboutUs;
