import axios from "./axios";
import { INews } from "../interfaces/news";

let controllerGetFiltered: AbortController | null = null;
let controllerGetByIds: AbortController | null = null;
let controllerGetAllHeading: AbortController | null = null;

export const update = async (news: INews) => {
  try {
    const { data } = await axios.post("api/news/update", { news });

    return data;
  } catch (e: any) {
    return e?.response?.data;
  }
};

export const remove = async (news: INews) => {
  try {
    const { data } = await axios.post("api/news/remove", { news: { ...news, relevance_status: false } });

    return data;
  } catch (e: any) {
    return e?.response?.data;
  }
};

export const create = async (news: INews) => {
  try {
    const { data } = await axios.post("api/news/create", { news });
    return data;
  } catch (e: any) {
    return e?.response?.data;
  }
};

export const getFiltered = async ({ search = "", page = 1, pageSize = 10 }: { search: string | null; page: number; pageSize: number }) => {
  try {
    if (controllerGetFiltered !== null) {
      controllerGetFiltered.abort();
    }
    controllerGetFiltered = new AbortController();
    const { data } = await axios.post("api/news/getFiltered", { search, page, pageSize }, { signal: controllerGetFiltered.signal });
    return data;
  } catch (e) {
    if (axios.isCancel(e)) {
      return console.log("Request canceled", e.message);
    } else {
      return console.error(e);
    }
  }
};

export const getByIds = async ({ ids }: { ids: number[] }) => {
  try {
    if (controllerGetByIds !== null) {
      controllerGetByIds.abort();
    }
    controllerGetByIds = new AbortController();
    const { data } = await axios.post("api/news/getByIds", { ids }, { signal: controllerGetByIds.signal });
    return data;
  } catch (e) {
    if (axios.isCancel(e)) {
      return console.log("Request canceled", e.message);
    } else {
      return console.error(e);
    }
  }
};

export const getAllHeading = async () => {
  try {
    if (controllerGetAllHeading !== null) {
      controllerGetAllHeading.abort();
    }
    controllerGetAllHeading = new AbortController();
    const { data } = await axios.get("api/news/getAllHeading", { signal: controllerGetAllHeading.signal });
    return data;
  } catch (e) {
    if (axios.isCancel(e)) {
      return console.log("Request canceled", e.message);
    } else {
      return console.error(e);
    }
  }
};

export default {
  update,
  remove,
  create,
  getFiltered,
  getByIds,
  getAllHeading,
};
