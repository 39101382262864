import { useState, useEffect } from "react";
import { useTheme, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, TextField, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Spinner from "react-bootstrap/Spinner";
import { customAlert } from "../../../components/Alert/AlertFunction";
import SheduleApi from "../../../api/shedule";
import EmailApi from "../../../api/email";
import { axiosGetUsersByGroup } from "../../../api/user";

function SendEmailByGroup({ sendEmailGroupId, setSendEmailGroupId, group }) {
  const theme = useTheme();
  const [usersByGroup, setUsersByGroup] = useState([]);
  const [currentShedule, setCurrentShedule] = useState([]);
  const [loadind, setLoading] = useState(false);
  const genderList = { 1: "Чоловіча", 2: "Жіноча" };
  const [email, setEmail] = useState({});

  async function getUsersByGroup(age_group) {
    setLoading(true);
    const result = await axiosGetUsersByGroup(age_group);
    console.log(1, result);
    if (result?.users) {
      setUsersByGroup(result?.users);
      setEmail((prev) => ({ ...prev, email: result?.users?.map((el) => el?.email) }));
    } else {
      customAlert({ message: result?.message || "Помилка отримання танцівників" });
    }
    setLoading(false);
  }

  async function getSheduleByGroup(age_group) {
    const result = await SheduleApi.getByGroup({ age_group });
    if (result?.shedule) {
      setCurrentShedule(result?.shedule);
    } else {
      customAlert({ message: result?.message || "Помилка отримання розкладу" });
    }
  }

  async function sendEmail({ email, currentShedule, usersByGroup }) {
    let emailData = { ...email };
    if (emailData?.text) {
      emailData.text = `${emailData?.text} \n`;
    }
    emailData.text = `${emailData?.text || ""}Дата Час початку Коментар
    ${currentShedule?.map((el) => `${el?.date} ${el?.time} ${el?.comment}`)?.join("\n")}`;
    const result = await EmailApi.send({ ...emailData });
    if (result && !result?.message) {
      customAlert({ message: "Успішно", severity: "success" });
    } else {
      customAlert({ message: result?.message || "Помилка відправлення електронного листа" });
    }
    setEmail((prev) => ({ ...prev, email: usersByGroup?.map((el) => el?.email) }));
  }

  useEffect(() => {
    if (!sendEmailGroupId) return;
    getUsersByGroup(sendEmailGroupId);
    getSheduleByGroup(sendEmailGroupId);
  }, [sendEmailGroupId]);

  return (
    <div style={{ background: "rgba(17, 17, 18, 0.95)" }} className="modalStyles">
      <div
        onClick={(e) => e.stopPropagation()}
        className="modalContentStyles scroll"
        style={{
          background: theme.palette.mode === "light" ? "#F3F1F2" : "#1b1b1d",
          color: theme.palette.text.primary,
          alignItems: "center",
          position: "relative",
          maxHeight: "80vh",
          //width: "80vh",
          minWidth: "650px",
          overflow: "auto",
        }}
      >
        <div style={{ position: "absolute", top: "1rem", right: "1rem", zIndex: "inherit" }}>
          <IconButton aria-label="delete" onClick={() => setSendEmailGroupId(0)}>
            <CloseIcon />
          </IconButton>
        </div>

        <h3 style={{ width: "100%", textAlign: "center" }}>{group?.name} група</h3>

        {!loadind ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" className="centerTable" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Ід</TableCell>
                  <TableCell>Ім'я користувача</TableCell>
                  <TableCell>Електронна пошта</TableCell>
                  <TableCell>Група</TableCell>
                  <TableCell>Телефон</TableCell>
                  <TableCell>Стать</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersByGroup?.map((item, index) => (
                  <TableRow key={item?.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell className="pointer" component="th" scope="row">
                      {item?.id}
                    </TableCell>
                    <TableCell>
                      {item?.name} {item?.last_name} {item?.surname}
                    </TableCell>
                    <TableCell>{item?.email}</TableCell>
                    <TableCell>{item?.ageGroup?.name}</TableCell>
                    <TableCell>{item?.telephone}</TableCell>
                    <TableCell>{genderList[item?.gender]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="newsContentHeight" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Spinner animation="border" role="status" style={{ height: "100px", width: "100px" }}></Spinner>
          </div>
        )}

        <div className="createBlock">
          <Typography className="createTitle" component="h2">
            Заголовок
          </Typography>
          <TextField className="createSelect" value={email?.subject} onChange={(e) => setEmail((prev) => ({ ...prev, subject: e?.target?.value }))} size="small" variant="outlined" type="text" />
        </div>
        <div className="createBlock">
          <Typography className="createTitle" component="h2">
            Коментар
          </Typography>
          <TextField className="createSelect" value={email?.text} onChange={(e) => setEmail((prev) => ({ ...prev, text: e?.target?.value }))} size="small" variant="outlined" type="text" />
        </div>

        <div style={{ width: "100%" }}>
          <Button onClick={() => sendEmail({ email, currentShedule, usersByGroup })} variant="outlined">
            Надіслати
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SendEmailByGroup;
