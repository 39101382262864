import { Form } from "react-bootstrap";
import validator from "validator";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Typography, useTheme, IconButton, Select, MenuItem, TextField } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CloseIcon from "@mui/icons-material/Close";
import { axiosUpdate, axiosGetAllGroups, axiosGetAllUsers } from "../../../api/user";
import { customAlert } from "../../../components/Alert/AlertFunction";
import { useAppSelector } from "../../../store/reduxHooks";
import { reducerTypes } from "../../../store/Users/types";

function UserUpdate({ userId, setIsOpen }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { allUsers } = useAppSelector((store) => store.user);
  const [currentUser, setCurrentUser] = useState({ telephone: "+380", gender: 0, age_group: 0, date_of_birth: null });
  const [allGroups, setAllGroups] = useState([]);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [passwordV2, setPasswordV2] = useState("");
  const [emailDirty, setEmailDirty] = useState(false);
  const [passwordDirty, setPasswordDirty] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordNoChect, setpasswordNoChect] = useState("");
  const [formValid, setFormValid] = useState(true);
  const [errorEmail, setErrorEmail] = useState("");

  const blurHandler = (e) => {
    switch (e.currentTarget.name) {
      case "login":
        setEmailDirty(true);
        break;
      case "password":
        setPasswordDirty(true);
        break;
      default:
    }
  };

  function loginUser(e) {
    setLogin(e.currentTarget.value);
    setErrorEmail("");
    if (!validator.isEmail(e.currentTarget.value)) {
      setEmailError("Електронна адреса неправильна");
    } else {
      setEmailError("");
    }
  }

  function passwordUser(e) {
    setPassword(e.currentTarget.value);
    if (!validator.isStrongPassword(e.currentTarget.value, { minSymbols: 0, minUppercase: 0 })) {
      setPasswordError("Мінімальна довжина 8 і мінімум 1 номер");
      if (!e.currentTarget.value) {
        setPasswordError("Пароль порожній");
      }
    } else {
      setPasswordError("");
    }
  }

  function passwordV2User(a) {
    setPasswordV2(a.currentTarget.value);
    if (!validator.isStrongPassword(a.currentTarget.value, { minSymbols: 0, minUppercase: 0 })) {
      setpasswordNoChect("Пароль неправильний");
      if (!a.currentTarget.value) {
        setpasswordNoChect("Пароль порожній");
      }
    } else {
      setpasswordNoChect("");
    }
  }

  function offReserch(e) {
    e.preventDefault();
  }

  async function getAllUsers() {
    const data = await axiosGetAllUsers();
    if (data) {
      dispatch({
        type: reducerTypes.GET_ALL_USERS,
        payload: data,
      });
    }
  }

  async function updateUser(e) {
    offReserch(e);
    const result = await axiosUpdate({ email: login, password, ...currentUser });
    if ((typeof result === "string" || !result) && result !== "Успішно оновлено") {
      console.log(1, result);
      customAlert({ message: result || "Помилка оновлення" });
    } else {
      customAlert({ message: "Успіх", severity: "success" });
      setIsOpen(false);
      getAllUsers();
      console.log("success");
    }
  }

  async function getAllGroups() {
    const data = await axiosGetAllGroups();
    if (data) {
      setAllGroups(data);
    } else {
      customAlert({ message: "Помилка отримання вікових груп" });
    }
  }

  useEffect(() => {
    if (password === passwordV2) {
      setpasswordNoChect();
    } else {
      setpasswordNoChect("Паролі не співпадають");
    }
  }, [password, passwordV2]);

  useEffect(() => {
    if (emailError || passwordError || passwordNoChect) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [emailError, passwordError, passwordNoChect]);

  useEffect(() => {
    const user = allUsers?.find((el) => el?.id === userId) || {};
    setCurrentUser({ telephone: "+380", gender: 0, age_group: 0, ...user });
    setLogin(user?.email);
    setPassword(user?.password);
    setPasswordV2(user?.password);
  }, [allUsers, userId]);

  useEffect(() => {
    getAllGroups();
  }, []);

  return (
    <div
      // onClick={() => setIsOpen(false)}
      style={{ background: "rgba(17, 17, 18, 0.95)" }}
      className="modalStyles"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="modalContentStyles scroll"
        style={{
          background: theme.palette.mode === "light" ? "#F3F1F2" : "#1b1b1d",
          color: theme.palette.text.primary,
          alignItems: "baseline",
          position: "relative",
          maxHeight: "80vh",
          //width: "80vh",
          overflow: "auto",
        }}
      >
        <div style={{ position: "absolute", top: "1rem", right: "1rem", zIndex: "inherit" }}>
          <IconButton aria-label="delete" onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <h3>Оновити дані користувача</h3>
        <hr className="hr-viss" />
        <Form className="width-form" style={{ textAlign: "left", fontSize: "16px" }}>
          <Form.Group controlId="formBasicEmailV11" style={{ display: "flex", flexDirection: "column", width: "80%" }}>
            <Form.Label>Ім'я користувача:</Form.Label>
            <TextField value={currentUser?.name || ""} onChange={(e) => setCurrentUser((prev) => ({ ...prev, name: e?.target?.value }))} size="small" variant="outlined" type="text" />
          </Form.Group>
          <Form.Group controlId="formBasicEmailV11" style={{ display: "flex", flexDirection: "column", width: "80%" }}>
            <Form.Label>Прізвище:</Form.Label>
            <TextField value={currentUser?.last_name || ""} onChange={(e) => setCurrentUser((prev) => ({ ...prev, last_name: e?.target?.value }))} size="small" variant="outlined" type="text" />
          </Form.Group>
          <Form.Group controlId="formBasicEmailV11" style={{ display: "flex", flexDirection: "column", width: "80%" }}>
            <Form.Label>По-батькові:</Form.Label>
            <TextField value={currentUser?.surname || ""} onChange={(e) => setCurrentUser((prev) => ({ ...prev, surname: e?.target?.value }))} size="small" variant="outlined" type="text" />
          </Form.Group>
          <Form.Group controlId="formBasicEmailV2" style={{ display: "flex", flexDirection: "column", width: "80%" }}>
            <Form.Label>Електронна пошта:</Form.Label>
            <TextField value={login} onChange={loginUser} onBlur={(e) => blurHandler(e)} size="small" variant="outlined" type="email" />
            {errorEmail ? <div style={{ color: "red" }}>{errorEmail}</div> : ""}
            {emailDirty && emailError && <div style={{ color: "red" }}>{emailError}</div>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPasswordV1" style={{ display: "flex", flexDirection: "column" }} size="small">
            <Form.Label>Телефон:</Form.Label>
            <MuiTelInput style={{ width: "80%" }} value={currentUser?.telephone} onChange={(e) => setCurrentUser((prev) => ({ ...prev, telephone: e }))} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPasswordV1" style={{ display: "flex", flexDirection: "column" }}>
            <Form.Label>Стать:</Form.Label>
            <Select style={{ width: "80%" }} value={currentUser?.gender} onChange={(e) => setCurrentUser((prev) => ({ ...prev, gender: e?.target?.value }))} size="small">
              <MenuItem value={0}>Не вказано</MenuItem>
              <MenuItem value={1}>Чоловіча</MenuItem>
              <MenuItem value={2}>Жіноча</MenuItem>
            </Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPasswordV1" style={{ display: "flex", flexDirection: "column" }}>
            <Form.Label>Вікова група:</Form.Label>
            <Select style={{ width: "80%" }} value={currentUser?.age_group} onChange={(e) => setCurrentUser((prev) => ({ ...prev, age_group: e?.target?.value }))} size="small">
              <MenuItem value={0}>Не вказано</MenuItem>
              {allGroups?.map((el) => (
                <MenuItem key={el?.id} value={el?.id}>
                  {el?.name}
                </MenuItem>
              ))}
            </Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPasswordV1" style={{ display: "flex", flexDirection: "column", width: "80%" }}>
            <Form.Label>Дата народження:</Form.Label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker value={currentUser?.date_of_birth ? dayjs(currentUser?.date_of_birth) : null} onChange={(e) => setCurrentUser((prev) => ({ ...prev, date_of_birth: new Date(e) }))} />
            </LocalizationProvider>
          </Form.Group>

          <Form.Group controlId="formBasicEmailV11" style={{ display: "flex", flexDirection: "column", width: "80%" }}>
            <Form.Label>Пароль:</Form.Label>
            <TextField value={password} onChange={passwordUser} onBlur={(e) => blurHandler(e)} size="small" variant="outlined" type="password" />
            {passwordDirty && passwordError && <div style={{ color: "red" }}>{passwordError}</div>}
          </Form.Group>

          <Form.Group controlId="formBasicEmailV11" style={{ display: "flex", flexDirection: "column", width: "80%" }}>
            <Form.Label>Підтвердіть пароль:</Form.Label>
            <TextField value={passwordV2} onChange={passwordV2User} size="small" variant="outlined" type="password" />
            {passwordNoChect && <Typography style={{ color: "red" }}>{passwordNoChect}</Typography>}
          </Form.Group>
          <div style={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}>
            <Button disabled={!formValid} onClick={(e) => updateUser(e)}>
              Оновити дані
            </Button>
            <Button onClick={(e) => setIsOpen(false)}>Назад</Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default UserUpdate;
