import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderContainer } from "./Header.styled";
import { Menu, MenuItem, Typography, Button, useTheme } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useAppSelector } from "../../store/reduxHooks";

function Header({ theme, setTheme }) {
  const navigate = useNavigate();
  const currentTheme = useTheme();
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const profileMenuOpen = Boolean(anchorElProfile);
  const { user } = useAppSelector((store) => store.user);

  function changeTheme() {
    setTheme((prev) => {
      const newTheme = prev === "light" ? "dark" : "light";
      localStorage.setItem("theme", newTheme);
      return newTheme;
    });
    setAnchorElProfile(null);
  }

  //if (window.location.href.includes("login")) return null;

  return (
    <HeaderContainer theme={currentTheme}>
      <div className="container">
        <Typography
          className={`button ${(window.location.href.includes("homepage") || window.location.href === "http://localhost:3000/") && "active"}`}
          variant="body1"
          component="h2"
          onClick={() => navigate("homepage")}
        >
          ГОЛОВНА СТОРІНКА
        </Typography>
        <Typography className={`button ${window.location.href.includes("aboutUs") && "active"}`} variant="body1" component="h2" onClick={() => navigate("aboutUs")}>
          ПРО НАС
        </Typography>
        <Typography className={`button ${window.location.href.includes("news") && "active"}`} variant="body1" component="h2" onClick={() => navigate("news")}>
          НОВИНИ
        </Typography>
        <Typography className={`button ${window.location.href.includes("video") && "active"}`} variant="body1" component="h2" onClick={() => navigate("video")}>
          ВІДЕО
        </Typography>
        {user?.role && (
          <Typography className={`button ${window.location.href.includes("shedule") && "active"}`} variant="body1" component="h2" onClick={() => navigate("shedule")}>
            РОЗКЛАД
          </Typography>
        )}
        {user?.role === "ADMIN" && (
          <Typography className={`button ${window.location.href.includes("users") && "active"}`} variant="body1" component="h2" onClick={() => navigate("users")}>
            КОРИСТУВАЧІ
          </Typography>
        )}
      </div>

      <Button onClick={(e) => setAnchorElProfile((prev) => (!!prev ? null : e.currentTarget))} variant="outlined" sx={{ margin: "8px", position: "absolute", right: "0px" }}>
        <AccountCircleIcon className="userIcon" />
      </Button>
      <Menu id="basic-menu" anchorEl={anchorElProfile} open={profileMenuOpen} onClose={() => setAnchorElProfile(null)} MenuListProps={{ "aria-labelledby": "basic-button" }}>
        <MenuItem onClick={changeTheme}>{theme === "dark" ? <LightModeIcon sx={{ width: "100%" }} /> : <DarkModeIcon sx={{ width: "100%" }} />}</MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/login/true");
            setAnchorElProfile(null);
          }}
        >
          <ReplayIcon sx={{ width: "100%" }} />
        </MenuItem>
      </Menu>
    </HeaderContainer>
  );
}

export default Header;
