export const reducerTypes = {
  GET_USER: "reducer/GET_USER",
  GET_ALL_USERS: "reducer/GET_ALL_USERS",
  GET_NEWS: "reducer/GET_NEWS",
  GET_VIDEO: "reducer/GET_VIDEO",
  GET_SHEDULE: "reducer/GET_SHEDULE",
  GET_ALERT: "reducer/GET_ALERT",
};

export const sagaTypes = {
  GET_USER: "saga/GET_USER",
};
