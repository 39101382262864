import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TextField, Typography, Button } from "@mui/material";
import Spinner from "react-bootstrap/Spinner";
import { useAppSelector } from "../../store/reduxHooks";
import { reducerTypes } from "../../store/Users/types";
import NewsApi from "../../api/news";
import { PageContainer } from "../../components/Page.styled";
import PaginationBlock from "../../components/forPages/PaginationBlock";
import { customAlert } from "../../components/Alert/AlertFunction";
import NewsCard from "./components/NewsCard";
import NewsCreate from "./components/NewsCreate";

function News() {
  const dispatch = useDispatch();
  const { user } = useAppSelector((store) => store.user);
  const [search, setSearch] = useState("");
  const [searchForInput, setSearchForInput] = useState("");
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(1);
  const [loadind, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  async function getFilteredNews({ page, search }) {
    setLoading(true);

    const result = await NewsApi.getFiltered({
      page: page + 1,
      pageSize: 10,
      search,
    });
    setLoading(false);
    if (result) {
      if (result?.news) {
        setNews(result?.news);
        dispatch({
          type: reducerTypes.GET_NEWS,
          payload: result?.news,
        });
      }

      if (result?.count) {
        setCount(result?.count);
      }
    } else {
      customAlert({ message: "Помилка отримання новин" });
      setNews([]);
      setCount(1);
    }
  }

  async function createNews({ news }) {
    news = { ...news, text: news?.text?.split("/n/"), images: news?.images?.split("/n/") };
    const result = await NewsApi.create(news);
    if (result && !result?.message) {
      customAlert({ message: "Success", severity: "success" });
      setIsOpen(false);
      getFilteredNews({ page, search });
    } else {
      customAlert({ message: result?.message || "Помилка створення новини" });
    }
  }

  useEffect(() => {
    getFilteredNews({ page, search });
  }, [page, search]);

  return (
    <PageContainer>
      <div style={{ display: "flex", justifyContent: "center", paddingBottom: "1rem" }}>
        <Typography variant="h2">СВІЖІ НОВИНИ</Typography>
      </div>
      <div style={{ marginBottom: "1rem", display: "flex", justifyContent: "space-between", position: "relative", zIndex: 1, gap: "1rem", paddingBottom: "2rem", borderBottom: "1px solid gray" }}>
        <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <TextField
            size="small"
            label="Пошук"
            variant="outlined"
            id="Search"
            value={searchForInput}
            onChange={(e) => setSearchForInput(e.target.value?.toLowerCase())}
            onBlur={(e) => {
              setPage(0);
              setSearch(e.target.value?.toLowerCase()?.trim());
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                setPage(0);
                setSearch(e.target.value?.toLowerCase()?.trim());
              }
            }}
          />
        </div>
        {user?.role === "ADMIN" && (
          <div>
            <Button onClick={() => setIsOpen((prev) => !prev)} variant="outlined">
              Створити новину
            </Button>
          </div>
        )}
      </div>
      {isOpen && <NewsCreate setIsOpen={setIsOpen} createNews={createNews} user={user} />}

      {!loadind ? (
        <div className="newsContentHeight newsContainer">
          {news?.map((el) => (
            <NewsCard key={el?.id} news={el} />
          ))}
        </div>
      ) : (
        <div className="newsContentHeight" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Spinner animation="border" role="status" style={{ height: "200px", width: "200px" }}></Spinner>
        </div>
      )}

      <PaginationBlock count={count} page={page} setPage={setPage} />
    </PageContainer>
  );
}

export default News;
