import { useAppSelector } from "../../store/reduxHooks";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reducerTypes } from "../../store/Users/types";
import { axiosRemoveUser, axiosGetAllUsers } from "../../api/user";
import UserCreate from "./components/UserCreate";
import UserTableRow from "./components/UserTableRow";
import { TextField, Checkbox, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";
import { PageContainer } from "../../components/Page.styled";
import DeleteIcon from "@mui/icons-material/Delete";
import PaginationBlock from "../../components/forPages/PaginationBlock";
import { customAlert } from "../../components/Alert/AlertFunction";

function AllUsers() {
  const dispatch = useDispatch();
  const { user, allUsers } = useAppSelector((store) => store.user);
  const [search, setSearch] = useState("");
  const [filterAdmin, setFilterAdmin] = useState(true);
  const [filterModerator, setFilterModerator] = useState(true);
  const [filterChater, setFilterChater] = useState(true);
  const [filterUser, setFilterUser] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [deleteUsers, setDeleteUsers] = useState([]);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  async function getAllUsers() {
    const data = await axiosGetAllUsers();
    if (data) {
      dispatch({
        type: reducerTypes.GET_ALL_USERS,
        payload: data,
      });
    }
  }

  function changeDeleteUsers(checked, id) {
    if (checked) {
      setDeleteUsers((prev) => [...prev, id]);
    } else {
      setDeleteUsers((prev) => prev.filter((item) => item !== id));
    }
  }

  useEffect(() => {
    setUsers(
      allUsers?.filter((el) =>
        search
          ? el?.email?.toLowerCase()?.includes(search) || el?.name?.toLowerCase()?.includes(search) || el?.last_name?.toLowerCase()?.includes(search) || el?.surname?.toLowerCase()?.includes(search)
          : true
      )
    );
  }, [allUsers, search, filterAdmin, filterModerator, filterChater, filterUser, completed]);

  useEffect(() => {
    if (!user) {
      navigate(`/homepage`);
    }
    getAllUsers();
    // eslint-disable-next-line
  }, [user]);

  return (
    <PageContainer>
      <div style={{ display: "flex", justifyContent: "center", marginBottom: "1rem" }}>
        <Typography variant="h2">Користувачі</Typography>
      </div>
      <div style={{ marginBottom: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", height: "41px" }}>
        <TextField size="small" label="Пошук" variant="outlined" type="search" id="Search" value={search} onChange={(e) => setSearch(e.target.value?.toLowerCase())} />
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: "1rem", alignItems: "center", marginTop: "5px" }}>
          {isOpen === false ? (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", marginTop: "5px" }}>
              <Button onClick={() => setIsOpen(true)}>Створити користувача</Button>
            </div>
          ) : (
            <UserCreate setIsOpen={setIsOpen} getAllUsers={getAllUsers} />
          )}
          <Button
            variant="outlined"
            onClick={async () => {
              await Promise.all(deleteUsers?.map(async (id) => await axiosRemoveUser(Number(id), user.id)));
              setDeleteUsers([]);
              await getAllUsers();
              customAlert({ message: "Success", severity: "success" });
            }}
            hidden={!deleteUsers[0]}
          >
            <DeleteIcon />
          </Button>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" className="centerTable" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Ід</TableCell>
              <TableCell>Ім'я користувача</TableCell>
              <TableCell>Роль</TableCell>
              <TableCell>Електронна пошта</TableCell>
              <TableCell>Видалити</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              ?.sort((a, b) => a.id - b.id)
              .slice(page * 10, (page + 1) * 10)
              ?.map((item) => (
                <UserTableRow key={item?.id} item={item} changeDeleteUsers={changeDeleteUsers} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationBlock count={Math.ceil(users?.length / 10)} page={page} setPage={setPage} />
    </PageContainer>
  );
}

export default AllUsers;
