import axios from "./axios";

export const send = async ({ email, subject, text }: { email: string[]; subject: string; text: string }) => {
  try {
    const { data } = await axios.post("api/email/send", { email, subject, text });

    return data;
  } catch (e: any) {
    return e?.response?.data;
  }
};

export default {
  send,
};
