import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { TextField, Pagination, Stack, Slider, Box } from "@mui/material";

function PaginationBlock({ count, page, setPage }) {
  return (
    <>
      <Pagination className="paginationButtonsWithoutPosition" color="primary" count={count} onChange={(e, value) => setPage(Number(value) - 1)} page={Number(page + 1)} />
    </>
  );
}

export default PaginationBlock;
