export const getFormatTime = (item) => {
  return Number(item?.time?.split(":")[0]);
};

export const getFormatTimeByDate = (value) => {
  const date = new Date(value);
  const time = date.toLocaleTimeString("en-US", { hour12: false });
  return time;
};

export const getFormatTimeByTime = (time) => {
  return Number(time?.split(":")[0]);
};

export const getFormatDate = (item) => {
  const date = new Date(item);
  if (date == "Invalid Date") {
    return item;
  }
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day < 10 ? `0${day}` : day}.${month < 10 ? `0${month}` : month}.${year}`;
};

export const getFormatDateWithoutYear = (item) => {
  const date = new Date(item);
  if (date == "Invalid Date") {
    return item;
  }
  const day = date.getDate();
  const month = date.getMonth() + 1;
  return `${day < 10 ? `0${day}` : day}.${month < 10 ? `0${month}` : month}`;
};

export const getCorrectTime = (date) => {
  if (!date) return;
  let correctDate = new Date(date);
  correctDate.setDate(correctDate.getDate() + 1);
  correctDate.setHours(correctDate.getHours() + 2);
  return correctDate.toISOString().split("T")[1]?.split(":")?.slice(0, 2).join(":");
};

export const replaceDots = (date) => String(date)?.replaceAll("-", ".");

export const getMockSelectOptions = (length) => {
  const result = [];

  for (let i = 0; i < length; i++) {
    const newObj = {
      id: i + 1,
      name: `${i + 1}`,
    };

    result.push(newObj);
  }

  return result;
};

export const colors = {
  blue: "#9FC5E9",
  darkBlue: "#3c78d8",
  skyColor: "#00FFFF",
  purple: "#BD90A7",
  greenLight: "#D8EAD2",
  greenDark: "#69A84F",
  green: "#00FF00",
  red: "#FF0000",
  pink: "#E99998",
  sheetPink: "#dbb898",
  orange: "#FFD967",
  yellow: "#FFE59A",
  lightYellow: "#f2ff00",
  grey: "#9c9fb7",
  purpleGrey: "#6b6d7f",
  rec_fill_half_green: "#b7e1cd",
};

export const getRandomRGBA = (opacity = 1) => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  const rgbColor = `rgb(${r}, ${g}, ${b}, ${opacity})`;

  return rgbColor;
};

export const timeColors = [colors.blue, colors.orange, colors.purple];
export const contractStatusColors = ["white", colors.orange, colors.skyColor];
export const cityStatusColors = [];
export const regimentColors = ["white", colors.blue, colors.orange, colors.green, "white", colors.purple];
export const reservationStatusColumnColors = ["white", colors.orange, colors.green, colors.greenLight, colors.pink];
export const reservationStatusColors = ["white", colors.greenLight, colors.greenDark, colors.greenDark, colors.red, colors.pink, colors.grey, colors.purpleGrey, colors.skyColor, colors.blue];
export const trailsAnalizeColumnsColors = ["white", colors.greenLight, colors.greenDark, colors.greenDark, colors.red, colors.pink, colors.grey, colors.purpleGrey, colors.skyColor, colors.blue];

export const getTableNameByCountry = (country, tableName, alterVariant) => {
  if (!country || !tableName || typeof country !== "string" || typeof tableName !== "string") return console.error("getTableNameByCountry: you must provide country name and table name as STRING");
  return alterVariant ? country.toLowerCase() + "_" + tableName.toLowerCase() : country.toLowerCase() + tableName.toLowerCase();
};

export const is7DaysFromNow = (dateString) => {
  const date = new Date(dateString);

  if (date < new Date()) {
    return false;
  }
  const differenceInMilliseconds = date - new Date();
  const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
  return differenceInMilliseconds > sevenDaysInMilliseconds;
};

export const getNestedValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

export function timeToMinutes(time) {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
}
