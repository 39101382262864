import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../store/reduxHooks";
import { reducerTypes } from "../../store/Users/types";
import NewsApi from "../../api/news";
import { PageContainer } from "../../components/Page.styled";
import { customAlert } from "../../components/Alert/AlertFunction";
import { TextField, Typography } from "@mui/material";
import { getFormatDate } from "../../utils/utils";

function NewsID() {
  const dispatch = useDispatch();
  let { id } = useParams();
  id = Number(id);
  const { news } = useAppSelector((store) => store.user);
  const [currentNews, setCurrentNews] = useState(news?.find((el) => el?.id === id));

  async function getNews(id) {
    if (!id) {
      return customAlert({ message: "Не знайдено ID новини" });
    }
    const data = await NewsApi.getByIds({ ids: [id] });
    if (data?.news) {
      dispatch({
        type: reducerTypes.GET_NEWS,
        payload: data?.news,
      });
      setCurrentNews(data?.news?.find((el) => el?.id === id));
    } else {
      customAlert({ message: "Новину не знайдено" });
    }
  }

  useEffect(() => {
    if (!currentNews) {
      getNews(id);
    }

    // eslint-disable-next-line
  }, [id, currentNews]);

  return (
    <PageContainer>
      <div className="newsIDContainer">
        <div className="name">
          <Typography variant="h3">{currentNews?.name}</Typography>

          <Typography variant="h5" style={{ color: "gray" }}>
            ОПУБЛІКОВАНО <span>{getFormatDate(currentNews?.createdAt)}</span> АВТОРОМ{" "}
            <span>
              {currentNews?.creator?.name} {currentNews?.creator?.last_name}
            </span>
          </Typography>
        </div>

        <div className="text">
          <Typography style={{ maxWidth: "700px" }} variant="h6">
            {currentNews?.title}
          </Typography>

          {currentNews?.text?.map((text, index) => (
            <Typography key={index} style={{ maxWidth: "700px" }} variant="h6">
              {text}
            </Typography>
          ))}
        </div>
      </div>
    </PageContainer>
  );
}

export default NewsID;
