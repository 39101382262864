import axios from "./axios";
import { IShedule } from "../interfaces/shedule";

let controllerGetFiltered: AbortController | null = null;
let controllerGetByIds: AbortController | null = null;
let controllerGetByGroup: AbortController | null = null;

export const update = async (shedule: IShedule) => {
  try {
    const { data } = await axios.post("api/shedule/update", { shedule });

    return data;
  } catch (e: any) {
    return e?.response?.data;
  }
};

export const remove = async (shedule: IShedule) => {
  try {
    const { data } = await axios.post("api/shedule/remove", { shedule: { ...shedule, relevance_status: false } });

    return data;
  } catch (e: any) {
    return e?.response?.data;
  }
};

export const create = async (shedule: IShedule) => {
  try {
    const { data } = await axios.post("api/shedule/create", { shedule });
    return data;
  } catch (e: any) {
    return e?.response?.data;
  }
};

export const getFiltered = async ({ page = 1, pageSize = 10 }: { page: number; pageSize: number }) => {
  try {
    if (controllerGetFiltered !== null) {
      controllerGetFiltered.abort();
    }
    controllerGetFiltered = new AbortController();
    const { data } = await axios.post("api/shedule/getFiltered", { page, pageSize }, { signal: controllerGetFiltered.signal });
    return data;
  } catch (e) {
    if (axios.isCancel(e)) {
      return console.log("Request canceled", e.message);
    } else {
      return console.error(e);
    }
  }
};

export const getByIds = async ({ ids }: { ids: number[] }) => {
  try {
    if (controllerGetByIds !== null) {
      controllerGetByIds.abort();
    }
    controllerGetByIds = new AbortController();
    const { data } = await axios.post("api/shedule/getByIds", { ids }, { signal: controllerGetByIds.signal });
    return data;
  } catch (e) {
    if (axios.isCancel(e)) {
      return console.log("Request canceled", e.message);
    } else {
      return console.error(e);
    }
  }
};

export const getByGroup = async ({ age_group }: { age_group: number }) => {
  try {
    if (controllerGetByGroup !== null) {
      controllerGetByGroup.abort();
    }
    controllerGetByGroup = new AbortController();
    const { data } = await axios.post("api/shedule/getByGroup", { age_group }, { signal: controllerGetByGroup.signal });
    return data;
  } catch (e: any) {
    if (axios.isCancel(e)) {
      console.log("Request canceled", e.message);
    } else {
      console.error(e);
      return e?.response?.data;
    }
  }
};

export default {
  update,
  remove,
  create,
  getFiltered,
  getByIds,
  getByGroup,
};
