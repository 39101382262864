import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TextField, Typography, Button } from "@mui/material";
import Spinner from "react-bootstrap/Spinner";
import { useAppSelector } from "../../store/reduxHooks";
import { reducerTypes } from "../../store/Users/types";
import Shedule from "../../api/shedule";
import { axiosGetAllGroups } from "../../api/user";
import { PageContainer } from "../../components/Page.styled";
import PaginationBlock from "../../components/forPages/PaginationBlock";
import { customAlert } from "../../components/Alert/AlertFunction";
import SheduleByGroup from "./components/SheduleByGroup";
import CreateShedule from "./components/CreateShedule";
import SendEmailByGroup from "./components/SendEmailByGroup";

function SheduleMain() {
  const dispatch = useDispatch();
  const { user } = useAppSelector((store) => store.user);
  const [allGroups, setAllGroups] = useState([]);
  const [loadind, setLoading] = useState(false);
  const [loadindCreate, setLoadingCreate] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [groupId, setGroupId] = useState(0);
  const [sendEmailGroupId, setSendEmailGroupId] = useState(0);

  async function getGroups() {
    setLoading(true);
    const data = await axiosGetAllGroups();
    if (data) {
      setAllGroups(data);
    } else {
      customAlert({ message: "Помилка отримання вікових груп" });
    }
    setLoading(false);
  }

  async function createShedule({ shedule }) {
    setLoadingCreate(true);
    const result = await Shedule.create(shedule);
    if (result && !result?.message) {
      customAlert({ message: "Success", severity: "success" });
      setIsOpen(false);
    } else {
      customAlert({ message: result?.message || "Помилка створення тренування" });
    }
    setLoadingCreate(false);
  }

  useEffect(() => {
    getGroups();
  }, []);
  return (
    <PageContainer>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h2">РОЗКЛАД</Typography>
      </div>
      <div style={{ marginBottom: "1rem", display: "flex", justifyContent: "space-between", position: "relative", zIndex: 1, gap: "1rem" }}>
        <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}></div>
        {user?.role === "ADMIN" && (
          <div>
            <Button onClick={() => setIsOpen((prev) => !prev)} variant="outlined">
              Додати тренування
            </Button>
          </div>
        )}
      </div>
      {isOpen && <CreateShedule setIsOpen={setIsOpen} createShedule={createShedule} allGroups={allGroups} loadindCreate={loadindCreate} />}
      {/* {isOpen && <NewsCreate setIsOpen={setIsOpen} createNews={createNews} user={user} />} */}

      {!loadind ? (
        <div className="newsContentHeight newsContainer">
          {(user?.role === "ADMIN" ? allGroups : allGroups?.filter((el) => el?.id === user?.age_group))?.map((el) => (
            <div
              key={el?.id}
              style={{ display: "flex", flexDirection: "column", gap: "2rem", alignItems: "center", border: "1px solid gray", padding: "2rem", boxShadow: "12px 12px 2px 1px rgba(0, 0, 255, .2)" }}
            >
              <Typography variant="h3">{el?.name} група</Typography>

              <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                <Button onClick={() => setGroupId(el?.id)} variant="outlined" size="large">
                  Дивитися
                </Button>
                {user?.role === "ADMIN" && (
                  <Button onClick={() => setSendEmailGroupId(el?.id)} variant="outlined" size="large">
                    Надіслати електронний лист
                  </Button>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="newsContentHeight" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Spinner animation="border" role="status" style={{ height: "200px", width: "200px" }}></Spinner>
        </div>
      )}

      {groupId ? <SheduleByGroup groupId={groupId} setGroupId={setGroupId} group={allGroups?.find((el) => el?.id === groupId)} /> : null}
      {sendEmailGroupId ? <SendEmailByGroup sendEmailGroupId={sendEmailGroupId} setSendEmailGroupId={setSendEmailGroupId} group={allGroups?.find((el) => el?.id === sendEmailGroupId)} /> : null}
    </PageContainer>
  );
}

export default SheduleMain;
