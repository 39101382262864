import { IAction } from "../utils";
import { reducerTypes } from "./types";
import { IUser } from "../../interfaces/users";
import { INews } from "../../interfaces/news";
import { IVideo } from "../../interfaces/video";
import { IShedule } from "../../interfaces/shedule";

function getAlertValue(state: IUsersReducer, payload: any) {
  if (payload.message) {
    return [...state.alert, { message: payload.message, id: new Date().getTime(), severity: payload?.severity }];
  } else {
    return state.alert.filter((el) => el.id !== payload.id);
  }
}

export interface IUsersReducer {
  user: IUser | {};
  allUsers: IUser[] | [];
  news: INews[] | [];
  video: IVideo[] | [];
  shedule: IShedule[] | [];
  alert: { id: number; message: string }[];
}

export const INITIAL: IUsersReducer = {
  user: {},
  allUsers: [],
  news: [],
  video: [],
  shedule: [],
  alert: [],
};

export const UserReducer = (state = INITIAL, { type, payload }: IAction) => {
  switch (type) {
    case reducerTypes.GET_USER:
      return { ...state, user: payload };
    case reducerTypes.GET_ALL_USERS:
      return { ...state, allUsers: payload };
    case reducerTypes.GET_NEWS:
      return { ...state, news: payload };
    case reducerTypes.GET_VIDEO:
      return { ...state, video: payload };
    case reducerTypes.GET_SHEDULE:
      return { ...state, shedule: payload };
    case reducerTypes.GET_ALERT:
      return { ...state, alert: getAlertValue(state, payload) };
    default:
      return state;
  }
};
