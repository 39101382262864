import axios from "./axios";
import { IVideo } from "../interfaces/video";

let controllerGetFiltered: AbortController | null = null;
let controllerGetByIds: AbortController | null = null;

export const update = async (videos: IVideo) => {
  try {
    const { data } = await axios.post("api/video/update", { videos });

    return data;
  } catch (e: any) {
    return e?.response?.data;
  }
};

export const remove = async (video: IVideo) => {
  try {
    const { data } = await axios.post("api/video/remove", { video: { ...video, relevance_status: false } });

    return data;
  } catch (e: any) {
    return e?.response?.data;
  }
};

export const create = async (videos: IVideo) => {
  try {
    const { data } = await axios.post("api/video/create", { videos });
    return data;
  } catch (e: any) {
    return e?.response?.data;
  }
};

export const getFiltered = async ({ search = "", page = 1, pageSize = 10 }: { search: string | null; page: number; pageSize: number }) => {
  try {
    if (controllerGetFiltered !== null) {
      controllerGetFiltered.abort();
    }
    controllerGetFiltered = new AbortController();
    const { data } = await axios.post("api/video/getFiltered", { search, page, pageSize }, { signal: controllerGetFiltered.signal });
    return data;
  } catch (e) {
    if (axios.isCancel(e)) {
      return console.log("Request canceled", e.message);
    } else {
      return console.error(e);
    }
  }
};

export const getByIds = async ({ ids }: { ids: number[] }) => {
  try {
    if (controllerGetByIds !== null) {
      controllerGetByIds.abort();
    }
    controllerGetByIds = new AbortController();
    const { data } = await axios.post("api/video/getByIds", { ids }, { signal: controllerGetByIds.signal });
    return data;
  } catch (e) {
    if (axios.isCancel(e)) {
      return console.log("Request canceled", e.message);
    } else {
      return console.error(e);
    }
  }
};

export default {
  update,
  remove,
  create,
  getFiltered,
  getByIds,
};
