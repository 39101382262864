import { useEffect, useState } from "react";
import { TextField, Checkbox, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";
import UserUpdate from "./UserUpdate";

function UserTableRow({ item, changeDeleteUsers }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <TableRow key={item.email} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell style={{ position: "relative" }} className="pointer" component="th" scope="row" onClick={() => setIsOpen(true)}>
        {item?.id}
        {isOpen && <UserUpdate setIsOpen={setIsOpen} userId={item?.id} />}
      </TableCell>
      <TableCell>
        {item.name} {item?.last_name} {item?.surname}
      </TableCell>
      <TableCell>{item.role}</TableCell>
      <TableCell>{item.email}</TableCell>
      <TableCell onChange={(e) => changeDeleteUsers(e.target.checked, item?.id)}>
        <Checkbox color="primary" />
      </TableCell>
    </TableRow>
  );
}

export default UserTableRow;
