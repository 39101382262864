import { useState } from "react";
import { Button, Typography, useTheme, IconButton, Select, MenuItem, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function VideoCreate({ setIsOpen, createVideo, user }) {
  const theme = useTheme();
  const [video, setVideo] = useState({});

  return (
    <div
      // onClick={() => setIsOpen(false)}
      style={{ background: "rgba(17, 17, 18, 0.95)" }}
      className="modalStyles"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="modalContentStyles scroll"
        style={{
          background: theme.palette.mode === "light" ? "#F3F1F2" : "#1b1b1d",
          color: theme.palette.text.primary,
          alignItems: "baseline",
          position: "relative",
          maxHeight: "80vh",
          //width: "80vh",
          overflow: "auto",
        }}
      >
        <div style={{ position: "absolute", top: "1rem", right: "1rem", zIndex: "inherit" }}>
          <IconButton aria-label="delete" onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <h3 style={{ width: "100%", textAlign: "center" }}>Створити відео</h3>
        <hr className="hr-viss" />
        <div className="createContainer">
          <div className="createBlock">
            <Typography className="createTitle" component="h2">
              Назва
            </Typography>
            <TextField className="createSelect" value={video?.name} onChange={(e) => setVideo((prev) => ({ ...prev, name: e?.target?.value }))} size="small" variant="outlined" type="text" />
          </div>
          <div className="createBlock">
            <Typography className="createTitle" component="h2">
              Заголовок
            </Typography>
            <TextField className="createSelect" value={video?.title} onChange={(e) => setVideo((prev) => ({ ...prev, title: e?.target?.value }))} size="small" variant="outlined" type="text" />
          </div>
          <div className="createBlock">
            <div>
              <Typography className="createTitle" component="h2">
                Текст:
              </Typography>
              <Typography className="createTitle" component="h2" style={{ color: "red" }}>
                вказуйте через "/n/"
              </Typography>
            </div>
            <TextField className="createSelect" value={video?.text} onChange={(e) => setVideo((prev) => ({ ...prev, text: e?.target?.value }))} size="small" variant="outlined" type="text" />
          </div>
          <div className="createBlock">
            <div>
              <Typography className="createTitle" component="h2">
                Посилання на зображення:
              </Typography>
              <Typography className="createTitle" component="h2" style={{ color: "red" }}>
                вказуйте через "/n/"
              </Typography>
            </div>
            <TextField className="createSelect" value={video?.images} onChange={(e) => setVideo((prev) => ({ ...prev, images: e?.target?.value }))} size="small" variant="outlined" type="text" />
          </div>
          <div className="createBlock">
            <div>
              <Typography className="createTitle" component="h2">
                Посилання на відео:
              </Typography>
            </div>
            <TextField className="createSelect" value={video?.link} onChange={(e) => setVideo((prev) => ({ ...prev, link: e?.target?.value }))} size="small" variant="outlined" type="text" />
          </div>
        </div>
        <Button onClick={() => createVideo({ video: { ...video, creator_id: user?.id } })} variant="outlined">
          Створити
        </Button>
      </div>
    </div>
  );
}

export default VideoCreate;
