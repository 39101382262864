import * as React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import noImage from "../../../image/noImage.jpg";

function NewsCard({ news }) {
  const navigate = useNavigate();
  const firstImage = (news?.images && news?.images[0]) || noImage;

  return (
    <div className="newsCard" onClick={() => navigate(`/news/${news?.id}`)}>
      <img className="cardImage" src={firstImage} width="300px" height="150px" />
      <div className="newsTitle">
        <Typography style={{ minWidth: "300px", color: "#ce0202" }} variant="body2">
          {news?.heading?.name || "БЕЗ РУБРИКИ"}
        </Typography>
        <Typography variant="h4">{news?.name}</Typography>

        <Typography variant="h8" style={{ maxWidth: "calc(100vw - 380px)", marginTop: "1rem" }}>
          {news?.title}
        </Typography>
        {news?.text?.map((el, index) => (
          <Typography key={index} variant="body2" style={{ maxWidth: "calc(100vw - 380px)" }}>
            {el}
          </Typography>
        ))}
      </div>
    </div>
  );
}

export default NewsCard;
