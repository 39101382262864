import * as React from "react";
import { TextField, Button, useTheme, Typography, Checkbox, FormControlLabel, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import noImage from "../../../image/noImage.jpg";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

function VideoCard({ video }) {
  const navigate = useNavigate();
  const firstImage = (video?.images && video?.images[0]) || noImage;
  const blockHeight = "150px";
  const videoId = String(video?.link)?.replaceAll("https://www.youtube.com/watch?v=", "");
  return (
    <div className="videoCard" style={{ height: blockHeight }} onClick={() => window.open(`${video?.link}`, "_blank")}>
      <div className="videoTitle">
        <Typography variant="h4">{video?.name}</Typography>

        <Typography variant="h6" style={{ maxWidth: "calc(100vw - 380px)", marginTop: "1rem" }}>
          {video?.title}
        </Typography>
        {/* {video?.text?.map((el, index) => (
          <Typography key={index} variant="body2" style={{ maxWidth: "calc(100vw - 380px)" }}>
            {el}
          </Typography>
        ))} */}
      </div>
      {/* <IconButton
        size="large"
        aria-label="delete"
        onClick={() =>
          window.open(
            `${video?.link}`,
            "_blank" // <- This is what makes it open in a new window.
          )
        }
      >
        <PlayCircleIcon />
      </IconButton> */}
      <img className="cardImage" src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`} width="300px" height={blockHeight} />
    </div>
  );
}

export default VideoCard;
