import { useState, useEffect } from "react";
import { useTheme, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Spinner from "react-bootstrap/Spinner";
import { customAlert } from "../../../components/Alert/AlertFunction";
import SheduleApi from "../../../api/shedule";

function SheduleByGroup({ groupId, setGroupId, group }) {
  const theme = useTheme();
  const [currentShedule, setCurrentShedule] = useState([]);
  const [loadind, setLoading] = useState(false);

  async function getSheduleByGroup(age_group) {
    setLoading(true);
    const result = await SheduleApi.getByGroup({ age_group });
    if (result?.shedule) {
      setCurrentShedule(result?.shedule);
    } else {
      console.log(1, result);
      customAlert({ message: result?.message || "Помилка отримання розкладу" });
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!groupId) return;
    getSheduleByGroup(groupId);
  }, []);

  return (
    <div style={{ background: "rgba(17, 17, 18, 0.95)" }} className="modalStyles">
      <div
        onClick={(e) => e.stopPropagation()}
        className="modalContentStyles scroll"
        style={{
          background: theme.palette.mode === "light" ? "#F3F1F2" : "#1b1b1d",
          color: theme.palette.text.primary,
          alignItems: "center",
          position: "relative",
          maxHeight: "80vh",
          //width: "80vh",
          minWidth: "650px",
          overflow: "auto",
        }}
      >
        <div style={{ position: "absolute", top: "1rem", right: "1rem", zIndex: "inherit" }}>
          <IconButton aria-label="delete" onClick={() => setGroupId(0)}>
            <CloseIcon />
          </IconButton>
        </div>

        <h3 style={{ width: "100%", textAlign: "center" }}>{group?.name} група розклад</h3>

        {!loadind ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, zoom: 1.5 }} aria-label="simple table" className="centerTable" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Дата</TableCell>
                  <TableCell>Час початку</TableCell>
                  <TableCell>Коментар</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentShedule?.map((shedule) => (
                  <TableRow key={shedule.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>{shedule?.date}</TableCell>
                    <TableCell>{shedule?.time}</TableCell>
                    <TableCell>{shedule?.comment}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="newsContentHeight" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Spinner animation="border" role="status" style={{ height: "200px", width: "200px" }}></Spinner>
          </div>
        )}
      </div>
    </div>
  );
}

export default SheduleByGroup;
