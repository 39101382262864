import { Route, Routes } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Homepage from "./pages/Homepage/Homepage";
import AboutUs from "./pages/AboutUs/AboutUs";
import News from "./pages/News/News";
import NewsID from "./pages/News/NewsID";
import AllVideo from "./pages/Video/AllVideo";
import UserInput from "./pages/UserInput/UserInput";
import UserID from "./pages/Users/UserID";
import AllUsers from "./pages/Users/AllUsers";
import "./style/body.css";
import { useAppSelector } from "./store/reduxHooks";
import { reducerTypes } from "./store/Users/types";
import Header from "./components/Header/Header";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Alert from "./components/Alert/Alert";
import SheduleMain from "./pages/Shedule/SheduleMain";

function App() {
  const dispatch = useDispatch();
  const { user } = useAppSelector((store) => store.user);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");

  const darkTheme = createTheme({
    palette: {
      secondary: {
        main: "#9596e2",
      },
      primary: {
        main: "#7688DA",
      },
      mode: theme,
    },
  });

  useEffect(() => {
    if (!user.id) {
      dispatch({
        type: reducerTypes.GET_USER,
        payload: localStorage.getItem("token") ? jwt_decode(localStorage.getItem("token")) : null,
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Header theme={theme} setTheme={setTheme} />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/homepage" element={<Homepage />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:id" element={<NewsID />} />
          <Route path="/video" element={<AllVideo />} />
          <Route path="/shedule" element={<SheduleMain />} />
          <Route path="/users" element={<AllUsers />} />
          <Route path="/users" element={<AllUsers />} />
          <Route path="/users/:id" element={<UserID />} />
          <Route path="/login/:logout" element={<UserInput />} />
          <Route path="/login/" element={<UserInput />} />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
        <Alert />
      </ThemeProvider>
    </>
  );
}

export default App;
