import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/reduxHooks";
import { PageContainer } from "../../components/Page.styled";

function Homepage() {
  const navigate = useNavigate();
  const { user } = useAppSelector((store) => store.user);

  return (
    <PageContainer style={{ padding: 0 }}>
      <div className="homepage">
        <div className="main">
          <Typography variant="h1" style={{ fontWeight: 600, textAlign: "center" }}>
            ДЖЕРЕЛЬЦЕ
          </Typography>
          <div className="dop">
            <Typography style={{ textAlign: "center", maxWidth: "800px", width: "70%" }} variant="h5">
              Зразковий художній колектив України ансамбль танцю "Джерельце"
            </Typography>
          </div>
          <div className="buttons">
            <Button size="large" variant="contained" onClick={() => navigate("/aboutUs")}>
              ПРО НАС
            </Button>
            <Button size="large" variant="outlined" onClick={() => navigate("/news")}>
              НОВИНИ
            </Button>
            <Button size="large" variant="outlined" onClick={() => navigate("/video")}>
              ВІДЕО
            </Button>
            {user?.role && (
              <Button size="large" variant="outlined" onClick={() => navigate("/shedule")}>
                РОЗКЛАД
              </Button>
            )}
            {user?.role === "ADMIN" && (
              <Button size="large" variant="outlined" onClick={() => navigate("/users")}>
                КОРИСТУВАЧІ
              </Button>
            )}
          </div>
        </div>
      </div>
    </PageContainer>
  );
}

export default Homepage;
