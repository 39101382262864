import styled from "styled-components";
import { useTheme } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import homepageBanner from "../image/home1.jpg";

export const PageContainer = styled.div(() => {
  const theme = useTheme();
  // console.log("theme", theme);
  return `
  // background: linear-gradient(122deg, #48788d, #83a8c7, #80bcce, #75b9e0); #F3F1F2 #1b1b1d
   background: ${theme.palette.mode === "light" ? "#F3F1F2" : "#1b1b1d"}; 
  min-height: calc(100vh - 60px);
  padding: 1rem;
  position: relative;
  color: ${theme.palette.text.primary};\

  .newsContentHeight {
    min-height: calc(100vh - 333px);
  }


  .newsIDContainer {
    padding: 3rem 5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem; 

    .name {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid gray;

      span {
        color: #ce0202;
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .newsContainer {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .newsCard {
      display: flex;
      flex-direction: row;
      gap: 1rem;


      &:hover {
        cursor: pointer;
        background: rgba(0,0,0,.1);

        .cardImage {
          filter: opacity(0.8) drop-shadow(0 0 0 white);
        }
      }
  
      .newsTitle {
        display: flex;
        flex-direction: column;
        gap: .2rem;
        max-height: 150px;
        overflow: hidden;
      }
    }

    
  }

  
  .videoContentHeight {
    min-height: calc(100vh - 333px);
  }

  .videoContainer {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;

    .videoCard {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      align-items: center;
      justify-content: space-around;


      &:hover {
        cursor: pointer;
        background: rgba(0,0,0,.1);

        .cardImage {
          filter: opacity(0.8) drop-shadow(0 0 0 white);
        }
      }
  
      .videoTitle {
        display: flex;
        flex-direction: column;
        gap: .2rem;
        max-height: 150px;
        overflow: hidden;
      }
    }

    
  }

  .homepage {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.5)), color-stop(53%,rgba(0,0,0,0.5)), color-stop(100%,rgba(0,0,0,0.5))),
    url(${homepageBanner}) no-repeat 90% 50%; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-direction: row;
    height: calc(100vh - 60px);
    gap: 1rem;
    justify-content: center;
    align-items: center;
    
    .main {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      color: white;
    }

    .dop {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 1rem;
    }
  }

  .aboutUs {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

      .direction {
        background: #f4f4f4;
        padding: 1rem 1rem 0;

        .cards {
          display: inline-grid;
          grid-template-rows: 1fr 1frr;
          grid-template-columns: 1fr 1fr;
          grid-gap: 3rem;
          justify-items: center;
          padding: 0 6rem;
        }
      }
  }

    .stickyFisCell {
      display: flex;
      flex-direction: column;
      height: 45px;
      align-items: center;
      padding: 0 10px;
      justify-content: center;
    }

    .customSelectForTable {
      background: none;
      border: 0px;
      border-bottom: 1px solid black;

      margin: 6px 6px;
      padding: 0 6px 4px;

      .css-io47o0-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input:not([multiple]) option {
        background-color: white;
      }
      option {
        color: black;
        padding: 6px 6px;
        margin: 6px;
        min-width: 200px;
        zoom: 1.2;

    }
  }

  .customSelectForTable:hover {
    cursor: pointer;
    border-bottom: 1px solid ${theme.palette.info.main};
  }

  .rowContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .columnContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .noBorder {
    border: 0;
  }

  .background {
    background: ${theme.palette.mode === "light" ? "#F3F1F2" : "#1b1b1d"}; 
  }

  .scroll::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

  .scroll::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #cccccc;
  }

  .bigScroll::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

  .bigScroll::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #cccccc;
  }

  .sizeForBases {
    min-width: 0;
  }

  .selectInputNoWrap {
    .css-17idfle-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select {
      white-space: unset;
    }
  }

  .basePadding {
    padding: 6px;
    input {
      padding: 8px 10px;
    }
  }

  .baseWidth {
    width: -webkit-fill-available;
  }

  .inputBaseID {
    max-width: 400px;
    max-height: 350px;
  }

  .checkboxOnWhiteBackground {
    .MuiSvgIcon-root  { 
      font-size: 28px; 
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .modalStyles {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(17, 17, 18, 0.65);
    display: flex;
    justify-content: center;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    flex-direction: column;
    z-index: 2;
  }

  .textArea {
    resize: none;
    text-align: center;
    border: none;

  }
  .tableHeader {
    background: ${theme.palette.mode === "light" ? "#DBDEE1" : "#242526"}; 
    color: ${theme.palette.text.primary};
  }

  .leaflet-routing-container {
    background: rgba(0,0,0,.7);
    .leaflet-routing-alt {
      table {
        display: none;
      }
    }
  }

  .createBlock {
    background: ${theme.palette.mode === "light" ? "#DBDEE1" : "#242526"};
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 15px 20px;
  }

  .createColumn {
    display: flex;
    flex-direction: column !important;
    align-items: center;
  }

  .createContainer {
    background: ${theme.palette.mode === "light" ? "#DBDEE1" : "#242526"};
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }

  .createTitle {
    width: 210px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .createSelect {
    width: 600px;
    text-align: left;
  }

  .disableBlackColor {
    input {
      -webkit-text-fill-color: ${theme.palette.text.primary};
    }
  }

  .disablePrimaryColor {
    .Mui-disabled {
      color: gray;
      border: 1px solid gray;
    }
    
  }

  .modalContentStyles {
    border: 1px solid ${theme.palette.text.primary};
    padding: 2rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .secondaryBack {
    background: ${theme.palette.info.main}
  }

  .mainBack {
    background: ${theme.palette.grey["800"]}
  }

  .black {
    .MuiInputBase-root {
      color: black;
    }

    .MuiFormLabel-root {
      color: black;
    }

    .MuiInputBase-root::before {
      border-bottom: 1px solid black;
    }

    .MuiSvgIcon-root {
      color: black;
    }

    .Mui-disabled {
      -webkit-text-fill-color: gray;
    }
    .css-1vbvugf-MuiButtonBase-root-MuiButton-root.Mui-disabled {
      -webkit-text-fill-color: lightgray;
      color: lightgray;
      border: 1px solid lightgray;
    }

    .Mui-disabled::before {
      border-bottom: 1px solid gray;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: gray;
    }
  }

  .bases {
    background: ${theme.palette.mode === "light" ? "#F3F1F2" : "#1b1b1d"}; 
  }

  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .centerTable {
    thead {
    tr {
      th {
        text-align: center;
        }
      } 
    }
    tbody {
      tr {
        th {
          text-align: center;
          }
        td {
          text-align: center;
          }
        } 
        }
    }

  .colorTable {
    tbody {
      background: ${theme.palette.mode === "light" ? "#F3F1F2" : "#1b1b1d"}; 
      td {
        color: ${theme.palette.text.primary};
      }
    }

    .tableInput {
      color: ${theme.palette.text.primary};
      font-size: 1rem;
      font-size: Max(1rem, 1em);
      font-family: inherit;
      padding: 0.25em 0.5em;
      background-color: unset;
      border: 2px solid var(--input-border);
      border-radius: 4px;
      transition: 180ms box-shadow ease-in-out;
      width: 7.5rem;
    }

    .tableInput[disabled] {
      color: ${theme.palette.text.primary};
      background-color: unset;
    }
  }

  .autoWidth {
      width: auto !important;
  }

  .pointer {
    cursor: pointer
  }

  .paginationButtons {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    position: absolute;
    bottom: 96px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .paginationButtonsWithoutPosition {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .paginationCount {
    position: absolute;
    right: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    bottom: 40px;
  }
  
  .paginationCountWithoutPosition {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .containerForTable {
  width: 100%;
  margin-top: 24px;



  .table-wrapper {
    min-height: 300px;
    color: text.primary;
    // overflow: auto;
    // padding-right: 12px;

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #e4e6ef;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #cccccc;
    }

    scrollbar-width: 6px;
    scrollbar-height: 6px;
    scrollbar-color: #cccccc #e4e6ef;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    min-width: 1200px;
  }

  thead {
    height: 50px;
    td {
      padding: 5px;
      font-family: "Verdana";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      // color: #0d0d0e;
    }

    tr {
      // position: sticky;
      // top: 0;
      z-index: 2;
      // background-color: #f2f3f4;
    }
  }

  .product-img {
    max-width: 160px;
    max-height: 150px;
    object-fit: contain;
  }

  tbody {
     
    td {
      max-width: 80px;
      padding: 5px;
      font-family: "Verdana";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #0d0d0e;
    }
  }

  .col-padding {
    padding: 7.5px 0;
  }

  .row-borders {
    border: 1px solid #cdcdcd;
  }

  .footer-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 24px;
  }

  .button-styled {
    width: 130px;
    padding: 6px 0;
  }

  .check-col:first-child {
    min-width: 30px;
    max-width: 30px;
  }

  .rekodow-col {
    min-width: 78.4px;
    max-width: 78.4px;
  }

  .checkbox-col {
    min-width: 85px;
    max-width: 85px;
  }

  .coming-col {
    min-width: 86px;
    max-width: 86px;
  }

  .Ilość-col {
    min-width: 87px;
    max-width: 87px;
  }

  .weryfikacja-col {
    min-width: 94px;
    max-width: 94px;
  }

  .podpinanie-col {
    min-width: 96px;
    max-width: 96px;
  }

  .scenario-col {
    min-width: 97.5px;
    max-width: 97.5px;
  }

  .limit-col {
    min-width: 100.8px;
    max-width: 100.8px;
  }

  .dzien-col {
    min-width: 130px;
    max-width: 130px;
  }

  .dodawanie-col {
    min-width: 140px;
    max-width: 140px;
  }

  .coming-check-col {
    background: #f2ffac;
  }

  .project-check-col {
    background: lightgreen;
  }

  .miasto-col {
    min-width: 250px;
    max-width: 250px;
  }

  .timezone-col {
    min-width: 78px;
    max-width: 78px;
  }

  .name-col {
    min-width: 100px;
    max-width: 100px;
  }

  .name-col {
    word-break: break-word;
  }

  .type-col {
    min-width: 70px;
    max-width: 70px;
  }

  .status-col {
    min-width: 70px;
    max-width: 70px;
  }

  .not-bold {
    font-weight: 400;
  }

  .details-col {
    min-width: 80px;
    max-width: 80px;
    cursor: pointer;

    div {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 4px;
    }
  }

  .default-col:first-child {
    min-width: 70.8px;
    max-width: 70.8px;
    height: 48px;
  }

  .icon-col {
    cursor: pointer;
  }

  .clickable {
    cursor: pointer;
  }

  .differences-col {
    background: rgba(177, 177, 177, 0.3);
    height: 48px;
    font-weight: 600;
  }

  .MuiSelect-select {
  }

  .paging {
    width: 100%;
    overflow: auto;
  }

}`;
});

export const OtherStyle = styled.div(() => {
  const theme = useTheme();
  return `
  .close {
    background: ${theme.palette.mode === "light" ? "#F3F1F2" : "#1b1b1d"};
    position: absolute;
    top: 1rem;
    right: 4rem;
    z-index: 402;
  }

  .close:hover {
    background: ${theme.palette.mode === "light" ? "#DBDEE1" : "#242526"}; 
    box-shadow: none;
  }

  .resize {
    min-width: calc(600px - 1rem - 60px);
    max-width: 80vw;
    min-height: 500px;
    max-height: 100vh;
    overflow: auto;
    resize: both;
  }

  .scroll::-webkit-scrollbar {
    width: 6px;
    height: 6px;

  .scroll::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #cccccc;
  }


  .editForm {
  background: ${theme.palette.mode === "light" ? "#F3F1F2" : "#1b1b1d"}; 
  color: ${theme.palette.text.primary};
  position: absolute;
  z-index: 401;
  padding: 2.5rem 1rem 2rem 60px;
  border: 1px solid black;

  .createContainer {
    background: ${theme.palette.mode === "light" ? "#DBDEE1" : "#242526"};
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }

  .createBlock {
    background: ${theme.palette.mode === "light" ? "#DBDEE1" : "#242526"};
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 15px 20px;
  }

  .createTitle {
    min-width: 165px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .createSelect {
    width: 100%;
    text-align: left;
  }

  .close {
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .edit {
    position: absolute;
    right: 2.5rem;
    top: 0px;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-right: 1rem;
    overflow: auto;
  }

  .spaceBetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  }

  .tableWithoutTdMaxWidth {
    td {
      max-width: none;
    }
  }
  `;
});

export const CustomTooltipMUI = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    fontSize: "16px",
    background: "rgba(0, 0, 0, .9)",
  },
});
