import React from "react";
import { Box, Typography } from "@mui/material";
import { purple } from "@mui/material/colors";

export default function PageNotFound() {
  const primary = purple[500];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: primary,
      }}
    >
      <Typography variant="h1" style={{ color: "white" }}>
        404
      </Typography>
      <Typography variant="h6" style={{ color: "white" }}>
        Сторінка, яку ви шукаєте, не існує
      </Typography>
    </Box>
  );
}
