import { TextField, Button, useTheme, Typography, Checkbox, FormControlLabel } from "@mui/material";

function PhotoCard({ direction }) {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem", color: "black", alignItems: "center" }}>
      <img src={direction?.image} style={{ maxHeight: "400px" }} width="50%" height="100%" />
      <Typography variant="h5">{direction?.name || ""}</Typography>
      <ul style={{ minHeight: "190px" }}>
        {direction?.text?.map((text, index) => (
          <li key={index}>
            <Typography variant="body">{text || ""}</Typography>
          </li>
        ))}
      </ul>
    </div>
  );
}
export default PhotoCard;
