import { IUser } from "../interfaces/users";
import axios from "./axios";
import jwt_decode from "jwt-decode";

let controllerGetAll: AbortController | null = null;
let controllerGetByGroup: AbortController | null = null;
let controllerGetAllGroup: AbortController | null = null;

export const axiosRegistration = async (user: IUser) => {
  try {
    const { data } = await axios.post("api/user/registration", { ...user, role: "DANCER" });
    if (!data.token) return;
    return jwt_decode(data.token);
  } catch (error: any) {
    return error?.response?.data?.message;
  }
};

export const axiosUpdate = async (user: IUser) => {
  try {
    const { data } = await axios.post("api/user/update", { user });
    return data;
  } catch (error: any) {
    return error?.response?.data?.message;
  }
};

export const axiosLogin = async (email: string, password: string) => {
  try {
    const { data } = await axios.post("api/user/login", { email, password });
    localStorage.setItem("token", data.token);
    axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    return jwt_decode(data.token);
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const check = async () => {
  if (!localStorage.getItem("token")) return;
  const { data } = await axios.get("api/user/auth");
  localStorage.setItem("token", data.token);
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  return jwt_decode(data.token);
};

export const axiosGetAllUsers = async () => {
  try {
    if (controllerGetAll !== null) {
      controllerGetAll.abort();
    }
    controllerGetAll = new AbortController();
    const { data } = await axios.get("api/user/get", { signal: controllerGetAll.signal });

    return data?.users;
  } catch (e) {
    console.error(e);
  }
};

export const axiosGetUsersByGroup = async (id: number) => {
  try {
    if (controllerGetByGroup !== null) {
      controllerGetByGroup.abort();
    }
    controllerGetByGroup = new AbortController();
    const { data } = await axios.post("api/user/getByGroup", { id }, { signal: controllerGetByGroup.signal });

    return data;
  } catch (e) {
    console.error(e);
  }
};

export const axiosGetAllGroups = async () => {
  try {
    if (controllerGetAllGroup !== null) {
      controllerGetAllGroup.abort();
    }
    controllerGetAllGroup = new AbortController();
    const { data } = await axios.get("api/user/getGroups", { signal: controllerGetAllGroup.signal });

    return data?.groups;
  } catch (e) {
    console.error(e);
  }
};

export const axiosChangeRole = async (role: string, id: number, creatorEmail: string, creatorPassword: string) => {
  try {
    const { data } = await axios.post("api/user/role", { role, id, creatorEmail, creatorPassword });

    return data;
  } catch (e) {
    console.error(e);
    return e;
  }
};

// export const axiosDeleteUser = async (id: number, creatorEmail: string, creatorPassword: string) => {
//     const { data } = await axios.post('api/user/delete', { id, creatorEmail, creatorPassword });
//     return data;
// };

export const axiosRemoveUser = async (id: number, adminID: number) => {
  const { data } = await axios.post("api/user/remove", { id, adminID });

  return data;
};
